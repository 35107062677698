import React, { useState } from 'react';
import { Col, Row } from 'components/Core/Grid';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import WebsiteImage from 'components/WebsiteImage';
import Button from 'components/Core/Button';
import collapseArrowSvg from 'images/collapse-arrow.svg';
import { BenefitsContainer, BenefitContainer, IconContainer } from './styles';

const Benefits = ({ entries }) => {
  const [showBenefits, toggleBenefits] = useState(false);
  const heading = entries.filter((entry) => entry.templateId === 'heading');
  const leftBenefits = entries.filter((entry) => entry.templateId.includes('left-benefit'));
  const rightBenefits = entries.filter((entry) => entry.templateId.includes('right-benefit'));

  return (
    <BenefitsContainer $showBenefits={showBenefits}>
      {showBenefits && (
        <MainContent heading={heading[0]} left={leftBenefits} right={rightBenefits} />
      )}
      <Button type="transparent - black border" onClick={() => toggleBenefits(!showBenefits)}>
        See all benefits <img className="collapse-arrow" src={collapseArrowSvg} />
      </Button>
    </BenefitsContainer>
  );
};

export default Benefits;

const renderBenefits = (benefitsArray) =>
  benefitsArray.map((benefit) => (
    <BenefitContainer>
      <IconContainer>
        <WebsiteImage {...benefit.listIcon} />
      </IconContainer>
      <ContentfulRichText richText={benefit} />
    </BenefitContainer>
  ));

const MainContent = ({ heading, left, right }) => (
  <>
    <Row>
      <Col sm={24}>
        <ContentfulRichText richText={heading} />
      </Col>
    </Row>
    <Row justify="space-between">
      <Col sm={24} md={11}>
        {renderBenefits(left)}
      </Col>
      <Col sm={24} md={11}>
        {renderBenefits(right)}
      </Col>
    </Row>
  </>
);
