import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

export const BenefitsContainer = styled(SectionLayout)`
  h2 {
    margin-bottom: 65px;
  }
  button {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 370px;
    width: 100%;
    box-shadow: none;
    font-weight: normal;
    font-size: var(--font-size-p);

    .collapse-arrow {
      margin-left: 15px;
      transform: ${({ $showBenefits }) => (!$showBenefits ? `rotate(180deg)` : '')};
      transition: transform 300ms;
    }
  }

  @media (max-width: 575px) {
    h2 {
      margin-bottom: 30px;
    }
    button {
      max-width: 208px;
      max-height: 53px;
      .collapse-arrow {
        margin-bottom: 3px;
      }
    }
  }
`;

export const BenefitContainer = styled.div`
  margin-bottom: 45px;
  display: flex;
  flex-direction: row;
  div p {
    padding: 5px 0px 5px 25px;
    margin-bottom: 0px;
  }
  @media (max-width: 575px) {
    margin-bottom: 40px;
  }
`;

export const IconContainer = styled.div`
  width: 30px;
  img {
    height: 100%;
  }
  @media (max-width: 575px) {
    padding-top: 7px;
    img {
      height: auto;
    }
  }
`;
